import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import '../blog.css';

import { AdminContext } from '../../../../context/AdminContext';

import { NIN_COVER } from '../../../../constants/admin/blog_constants';
import { GOOGLE_DRIVE_CDN } from '../../../../constants/main_constants';

//MUI
import {
    Box, Button, Card, CardContent, CardMedia, Container,
    Grid, Tooltip, Typography, useMediaQuery
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';

const BlogPreview = () => {
    const matches = useMediaQuery('(max-width: 950px)');

    const { data: { blog } } = useContext(AdminContext);
    
    const [blogPreview, setBlogPreview] = useState({
        blog_id: '0', title: '', publication_date: new Date(), content: [], author: ''
    });
    const { blog_id, title, publication_date, content, author } = blogPreview;
    console.log(publication_date)

    // blogs ya guardados
    const [items, setItems] = useState([]);
    
    const [copySuccess, setCopySuccess] = useState("Copiar");

    useEffect(() => {
        const PreviewData = localStorage.getItem('blog_preview');
        if (PreviewData) {
            const { publication_date, ...blog_data } = JSON.parse(PreviewData);
            setBlogPreview({
                ...blog_data, publication_date: new Date(publication_date)
            });
        }
    }, []);

    useEffect(() => {
        if (blog.length > 0 && blog_id !== '0')
            setItems(shuffleArray());
    }, [blogPreview]);

    const shuffleArray = () => {
        const blogList = blog.filter(e => e.blog_id !== blog_id);
        for (let i = blogList.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = blogList[i];
            blogList[i] = blogList[j];
            blogList[j] = temp;
        };
        return blogList.slice(0, 4);
    };

    const handlecopyToClip = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopySuccess("¡Enlace copiado al portapapeles!");
        setTimeout(() => {
            setCopySuccess("Copiar");
        }, 2000);
    };
    
    return (
        <Container maxWidth="xl" sx={{ mt: matches ? 5 : 18 }} className="last-view">
            <Grid container spacing={6}>
                <Grid item xl={8.5} lg={8.5} md={12} sm={12} xs={12}>
                    <Typography variant="h4" sx={{ fontWeight: '600' }} gutterBottom>
                        { title }
                    </Typography>
                    <Typography variant='body1' paragraph>
                        Última actualización: { format(new Date(publication_date), "d 'de' MMMM 'de' yyyy", { locale: es }) }
                    </Typography>
                    <Box sx={{ mb: 3 }}>
                        <img src={`${GOOGLE_DRIVE_CDN}${NIN_COVER}`} alt={'blog-NIN-Institute'} width="100%" />
                    </Box>
                    <Grid container spacing={2}>
                        {
                            content.map((e, i) =>
                                <Grid item xs={12} key={i}>
                                    <Grid container>
                                        {
                                            e.col.map(({ item, xs, md }, j) =>
                                                <Grid item xs={xs} md={md} key={j}>
                                                    <div className="myClass" dangerouslySetInnerHTML={{ __html: item }}></div>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>

                    <Box sx={{ my: 3 }}>
                        <Typography variant='body1'>
                            <b>Autor: </b>{ author }
                        </Typography>
                    </Box>

                    <Tooltip title={ copySuccess }>
                        <Button variant='contained' onClick={ handlecopyToClip }
                            sx={{ textTransform: 'none', mb: 2 }}
                            endIcon={<ShareIcon />}
                        >
                            Compartir
                        </Button>
                    </Tooltip>
                </Grid>

                <Grid item xl={3.5} lg={3.5} md={12} sm={12} xs={12}>
                    <Grid container spacing={4}>
                        {
                            items.map((e, index) => {
                                return (
                                    <Grid item xs={12} key={index}>
                                        <Card sx={{ width: '100%' }}>
                                            <CardMedia
                                                sx={{ height: 180 }}
                                                image={`${GOOGLE_DRIVE_CDN}${e.imgId}`}
                                                title={e.title}
                                                component={Link}
                                                to={`/blog/${e.url}`}
                                            />
                                            <CardContent>
                                                <Typography sx={{ fontSize: '18px', fontWeight: '600' }}
                                                    component="div" gutterBottom>
                                                    { e.title }
                                                </Typography>
                                                <Typography variant='body2' color='text.secondary'>
                                                    {`${e.preview}...`} <Link to={`/blog/${e.url}`}>Ver más</Link>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BlogPreview;