import { request } from "../helpers/request/requestHelper";

export const saveFormData = async (data) => {
    try {
        const { especialidad, especialidad2, email, protocol } = data;
        const form = {
            specialty: especialidad === 'Otro' ? especialidad2 : especialidad,
            email, protocol
        };
        const resp = await request('form/protocol', form, 'POST');
        const body = await resp.json();
        if (body.status)
            localStorage.setItem('form_email', email);
    } catch (err) {};
};

export const saveFormDataInf = async (data) => {
    try {
        const { especialidad, especialidad2, email, protocol } = data;
        const form = {
            specialty: especialidad === 'Otro' ? especialidad2 : especialidad,
            email, report: protocol
        };
        const resp = await request('form/scientific-report', form, 'POST');
        const body = await resp.json();
        console.log(body)
        if (body.status)
            localStorage.setItem('form_email_inf', email);
    } catch (err) {};
};